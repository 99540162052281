<template>
  <v-autocomplete
    v-model="pessoa"
    auto-select-first
    dense
    outlined
    return-object
    no-filter
    :label="label"
    :search-input.sync="pesquisa"
    :items="pessoas"
    item-text="nome"
    item-value="id"
    :loading="carregando"
    :error-messages="errorMessages"
    placeholder="Pesquise pelo nome, CPF ou CNPJ"
    autocomplete="off"
    hide-details="auto"
    clearable
    @change="pessoaSelecionada"
  >
    <template
      slot="selection"
      slot-scope="data"
    >
      {{ data.item.nome }}
    </template>
    <template
      slot="item"
      slot-scope="data"
    >
      {{ data.item.nome }} - {{ (data.item.cpf || data.item.cnpj) | cpfCnpj }}
    </template>
  </v-autocomplete>
</template>

<script>
import pessoasApi from '@/api/pessoas'

export default {
  props: {
    pessoaAdicionada: {
      type: Object,
      default: () => {},
    },
    errorMessages: {
      type: Array,
      default: () => {},
    },
    limparAposSelecionar: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Nome completo"
    },
  },

  data() {
    return {
      pessoas: [],
      carregando: false,
      pesquisa: '',
      pessoa: {},
      digitando: null
    }
  },

  watch: {
    pesquisa() {
      if (this.digitando !== null) {
        clearTimeout(this.digitando);
      }

      this.digitando = setTimeout(this.buscarPessoas, 750)
    },

    pessoaAdicionada(val) {
      if (!val) return

      this.pessoas = [val]
      this.pessoa = this.pessoas[0]

      this.pessoaSelecionada()
    },
  },

  methods: {
    async buscarPessoas() {
      if (this.pesquisa === null || this.pesquisa.length <= 0) return

      try {
        this.carregando = true

        const resposta = await pessoasApi.listar(`nome=${this.pesquisa},or&cpf=${this.pesquisa},or&cnpj=${this.pesquisa},or`)

        this.pessoas = resposta.data.pessoas
      } catch (e) {
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregando = false
      }
    },

    pessoaSelecionada() {
      const pessoaSel = {...this.pessoa}

      if (this.limparAposSelecionar) {
        this.pesquisa = ''
        this.pessoa = {}
        this.pessoas = []
      }

      this.$emit('change', pessoaSel)
    },
  },
}
</script>
